import store from '../../store';
import Synchronize from './Synchronize';
import Fkin from './Fkin';
import Eul2rotmat from "./Eul2rotmat";
import IkinPtp from "./IkinPtp";
import IkinPtpR6 from "./IkinPtpR6";

let math = require('mathjs');

class Ptp {

    run(command, qstart) {
        let robot = store.translation.input.robot;
        let N = robot.jointAmount;
        let joints = robot.joints;
        let Q = [];
        let qtemp = [];
        let i;
        let s_te;
        let t_te;
        let T0;
        let AW;
        let F;
        let synchronize = new Synchronize();
        let fkin = new Fkin();
        let eul2rotmat = new Eul2rotmat();
        let ikinPtp = new IkinPtp();
        let ikinPtpR6 = new IkinPtpR6();

        // cartesian
        if (parseInt(command.data.target, 10) === 1) {
            let pose = command.data.cartesianTargetPose;
            if (command.type === 'PTPREL') {
                t_te = fkin.run({...robot}, qstart);
                T0 = t_te[1];
                AW = eul2rotmat.run([pose[3], pose[4], pose[5]]);
                F = math.multiply(T0.subset(math.index([0, 1, 2], [0, 1, 2], N - 1)).resize([3, 3]), AW);
                F.subset(math.index([0, 1, 2], 3), math.add(T0.subset(math.index([0, 1, 2], 3, N - 1)).resize([3]), [pose[0], pose[1], pose[2]]));
            } else {
                F = eul2rotmat.run([pose[3], pose[4], pose[5]]);
                console.log('rotmat', F);
                F.subset(math.index([0, 1, 2], 3), [pose[0], pose[1], pose[2]]);
            }

            if(robot.name === 'R6') {
                qtemp = ikinPtpR6.run(F, qstart, robot, command.data.mask, store.translation.input.tIpo);
            } else {
                qtemp = ikinPtp.run(F, qstart, robot, command.data.mask, store.translation.input.tIpo);
                qtemp = math.squeeze(qtemp);
                qtemp = qtemp.toArray();
            }
            //qtemp = [math.subset(qtemp, math.index(0)), math.subset(qtemp, math.index(1)), math.subset(qtemp, math.index(2))];
            console.log('qtemp', qtemp);
        }
        // joint
        else {
            for (i = 0; i < N; i++) {
                if (command.type === 'PTPREL') {
                    if (parseInt(joints[i].h, 10) === 1) {
                        qtemp.push(command.data.jointTargetPose[i] * Math.PI / 180 + qstart[i]);
                    } else {
                        qtemp.push(command.data.jointTargetPose[i] + qstart[i]);
                    }
                } else {
                    if (parseInt(joints[i].h, 10) === 1) {
                        qtemp.push(command.data.jointTargetPose[i] * Math.PI / 180);
                    } else {
                        qtemp.push(command.data.jointTargetPose[i]);
                    }
                }
            }
        }

        // Gelenkkoordinaten der Zielpose ueberpruefen
        for (i = 0; i < N; i++) {
            if (qtemp[i] > joints[i].qmax || qtemp[i] < joints[i].qmin) {
                store.translation.status = 'Error';
                store.translation.errorMessage = 'Angle ' + (i+1) + ' out of range.';
                clearInterval(store.translation.timePassedInterval);
                return false;
            }
        }

        // Winkeldifferenz und Vorzeichen
        let se = [];
        let sgn = [];
        for (i = 0; i < N; i++) {
            se[i] = Math.abs(qtemp[i] - qstart[i]);
            sgn[i] = Math.sign(qtemp[i] - qstart[i]);
        }

        //console.log('qtemp', qtemp);
        //console.log('qstart', qstart);
        //console.log('se', se);
        //console.log('sgn', sgn);

        // Synchronisieren der Bahndauer fuer alle Gelenke
        s_te = synchronize.run({...command}, se, [...store.translation.input.vptp], [...store.translation.input.bptp], store.translation.input.tIpo, robot);
        let s = s_te[0];
        let te = s_te[1];

        //console.log('s_te', s_te);

        // Interpolation
        for (i = 0; i < s.length; i++) {
            for (let j = 0; j < N; j++) {
                if (j === 0) {
                    Q[i] = [];
                }
                if (te[j] === 0) {
                    Q[i][j] = qtemp[j];
                } else {
                    Q[i][j] = qstart[j] + s[i][j] * se[j] * sgn[j];
                }
            }
        }

        return Q;

    }
}

export default Ptp;
