import React, {Component} from 'react';
import RobotView from './components/views/RobotView';
import CommandsView from './components/views/CommandsView';
import TranslationView from './components/views/TranslationView';
import './App.css';
import './styles/mandy.css';
import {MenuItem, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6'
import {AuthProvider} from "oidc-react";
import store from './store.js';
//import fileDownload from "js-file-download";
import moment from 'moment';
import packageJson from '../package.json';
import CommandModel from "./model/CommandModel";
import CommandFormTargetPoseModel from "./model/CommandFormTargetPoseModel";
import CommandFormSpeedPtpModel from "./model/CommandFormSpeedPtpModel";
import CommandFormAuxiliaryPointAndTargetPoseModel from "./model/CommandFormAuxiliaryPointAndTargetPoseModel";
import CommandFormSpeedCpModel from "./model/CommandFormSpeedCpModel";
import CommandFormAccelCpModel from "./model/CommandFormAccelCpModel";
import CommandGripperModel from "./model/CommandGripperModel";
import CommandCommentModel from "./model/CommandCommentModel";
import CommandWaitModel from "./model/CommandWaitModel";

class App extends Component {

  constructor(props) {
    super(props);
    this.fileInputRed = React.createRef();
  }
/*
  exportToFile = () => {
    fileDownload(JSON.stringify({
      mandyVersion: packageJson.version,
      exportVersion: 1,
      robot: {...store.robot},
      commands: {...store.commands}
    }), `${moment().format('YYYY-MM-DD_HH-mm')}_${process.env.REACT_APP_TITLE}.json`, 'application/json')
  }
*/
  startImportFile = () => {
    if (this.fileInputRed.current) {
      this.fileInputRed.current.click();
    }
  }

  doImportFile = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      const data = JSON.parse(text);
      store.robot = data.robot;
      store.commands = [];
      if (data.commands) {
        for (let ci in data.commands) {
          let command = data.commands[ci];
          let commandModel = new CommandModel();
          commandModel.index = command.index;
          commandModel.type = command.type;
          switch (command.type) {
            case 'PTP':
            case 'PTPREL':
              commandModel.data = Object.assign(new CommandFormTargetPoseModel(data.robot, 2), command.data);
              break;
            case 'LIN':
            case 'LINREL':
              commandModel.data = Object.assign(new CommandFormTargetPoseModel(data.robot, 1), command.data);
              break;
            case 'SPEEDPTP':
            case 'ACCELPTP':
              commandModel.data = Object.assign(new CommandFormSpeedPtpModel(), command.data);
              break;
            case 'CIRC':
            case 'CIRCREL':
              commandModel.data = Object.assign(new CommandFormAuxiliaryPointAndTargetPoseModel(data.robot), command.data);
              break;
            case 'SPEEDCP':
              commandModel.data = Object.assign(new CommandFormSpeedCpModel(), command.data);
              break;
            case 'ACCELCP':
              commandModel.data = Object.assign(new CommandFormAccelCpModel(), command.data);
              break;
            case 'OPENGRIPPER':
            case 'CLOSEGRIPPER':
              commandModel.data = Object.assign(new CommandGripperModel(), command.data);
              break;
            case 'COMMENT':
              commandModel.data = Object.assign(new CommandCommentModel(), command.data);
              break;
            case 'WAIT':
              commandModel.data = Object.assign(new CommandWaitModel(), command.data);
              break;
            default:
              commandModel.data = null;
              break;
          }
          store.commands.push(commandModel);
          store.refreshCommandsIndex();
          store.commandValueChanged();
        }
      }
    };
    reader.readAsText(e.target.files[0])
    e.target.value = null;
  }

  renderApp = () => (
    <div className="main-container">
      <Navbar fixedTop={true}>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="">{process.env.REACT_APP_TITLE}</a>
          </Navbar.Brand>
        </Navbar.Header>
        <Nav pullRight>
          <NavDropdown title="Menu" id="basic-nav-dropdown">
            <MenuItem onClick={this.exportToFile}>In Datei speichern</MenuItem>
            <MenuItem onClick={this.startImportFile}>Aus Datei laden</MenuItem>
          </NavDropdown>
        </Nav>
      </Navbar>
      <RobotView/>
      <CommandsView/>
      <TranslationView/>
      <input type="file" ref={this.fileInputRed} className="hidden" accept="application/json" onChange={this.doImportFile}/>
    </div>
  )

  render() {
    return process.env.REACT_APP_OPID_ENABLED === 'true' ? (
      <AuthProvider authority={process.env.REACT_APP_OPID_AUTHORITY} clientId={process.env.REACT_APP_OPID_CLIENT_ID}
                    clientSecret={process.env.REACT_APP_OPID_CLIENT_SECRET}
                    redirectUri={process.env.REACT_APP_OPID_REDIRECT_URI}>
        {this.renderApp()}
      </AuthProvider>
    ) : this.renderApp();
  }
}

export default easyComp(App);
