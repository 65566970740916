//-> Import bnötigter Bibliotheken
import React, { Component } from 'react';
import { easyComp } from 'react-easy-state/dist/umd.es6';
import { Modal } from 'react-bootstrap';
import store from '../../store.js';
import { GUI }from 'dat.gui'; //->Live Gui
import * as THREE from 'three';
import { AxesHelper } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import $ from 'jquery';
//import numeric from 'numeric';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { BoxBufferGeometry, CylinderBufferGeometry } from 'three';
import Stats from 'stats.js'; //FPS Anzeige


// Definition global benötigter Variablen
const scene = new THREE.Scene();
let lastUpdate = Date.now();
var animationId = null;
var end = {pos: "", euler: ""};
var robotcolorchange = false;

class RobotAnimationModal extends Component {

    constructor(props) {
        super(props);
        this.camera = new THREE.PerspectiveCamera( /* ... */ );
        this.renderer = new THREE.WebGLRenderer( /* ... */ );
    }

    componentDidMount() {
        //Einstellungen für die FPS Anzeige, für die Anzeige muss weiterhin stat.begin und stat.end im Animationsloop auskommentiert werden
       // var stats = new Stats();
       // stats.showPanel(0); // 0: fps, 1: ms, 2: mb, etc.
       // document.body.appendChild(stats.dom);

        // Basiswerte der GUI, welche sich durch die GUI manipulieren lassen
        let settings = {
            speed: 10,
            animate:true,
            gripper:true,
            bohrer:false,
            waypoints:true,
            refpath:false,
            x: 0,
            y: 0,
            z: 0,
            xrot: 0,
            yrot: 0,
            zrot:0,
            robotcolor:0xf25c19
        };

        let Q = store.translation.output.Q; // Winkeländerungen
       // console.log(Q)
        let robot = JSON.parse(JSON.stringify(store.translation.input.robot)); // aufgrund der teils notwenigen deg zu Rad umrechnung bei Schubgelenken kam es bei dem mehrfachen öffnen des Modals zu ungewünschten nebeneffekten, daher wird eine "Kopie" erstellt
       // console.log(robot)
        let table = robot.joints; //DH Definition des Roboters
        let canvas = document.getElementById('canvas');   
        let matrix0 = new THREE.Matrix4(); //-> transformationsmatrix des Roboterursprungs
        

        var ThreeDRobot = function(canvas, table, Q) {

            THREE.Object3D.call(this);
            this.table = table;
            scene.add(this);

            //-> Three Renderer setup
            const renderer = new THREE.WebGLRenderer({
            antialias: true, // erzeugt eine etwas dynamischere Darstellung
            preserveDrawingBuffer: false, // trägt zu einer schnelleren Berechnung der Einzelenen Frames bei
            })

            // Größe des Animationsfensters
            //const width = window.innerWidth/1.8;
            //const height = window.innerHeight/1.2;
            //renderer.setSize(width, height);
            renderer.setSize(canvas.offsetWidth, 700);

            //-> Hintergrundfarbe der Animation
            renderer.setClearColor(0xFCFCFC)
            canvas.appendChild(renderer.domElement);

            //-> Basisdefinition der Kamera
            var camera = new THREE.PerspectiveCamera(45, canvas.offsetWidth / canvas.offsetHeight, 1, 10000);
            camera.up.set(0, 0, 1);
            camera.position.set(2, 3.5, 2.5);
            scene.add(camera)

            //-> Einschränken der Kamerabewegung
            const controls = new OrbitControls(camera, renderer.domElement);
            controls.maxDistance = 500.0;  //Maximaler Abstand der Kamera 
            controls.maxPolarAngle = Math.PI * 0.495; //Einschränkung des Rotationswinkels der Kamera
            controls.target.set(0, 0, 0);
            controls.addEventListener('change', () => renderer.render(scene, camera))
     
            //-> Szenenbeleuchtung (Grundsätzliche Belechtung sowie Punktlicht zum Erzeugen der Schattierung)
            const ambientlight = new THREE.AmbientLight( 0x404040, 5 );
            scene.add( ambientlight );
        
            const pointlight = new THREE.DirectionalLight( 0xffffff, 6 );
            pointlight.position.set(1, 1.3, 1).normalize();
            scene.add( pointlight );

            //-> Schachbretmuster des Bodens
            var gridHelper = new THREE.GridHelper(3, 10);
            gridHelper.rotateX(Math.PI / 2);
            scene.add(gridHelper);
        
            //-> Definition des Bodens der Animation
            var geometry = new THREE.PlaneGeometry( 3, 3, 1, 1 );
            var material = new THREE.MeshBasicMaterial({color:0xcccccc});
            var floor = new THREE.Mesh(geometry, material)
            floor.material.side = THREE.DoubleSide
            scene.add(floor)

            //-> Definition des Koordinatensystems im Ursprung 
            /*
            const axisHelper = new THREE.AxesHelper(1.5)
            const xAxisColor = new THREE.Color( 'red' );
            const yAxisColor = new THREE.Color( 'green' );
            const zAxisColor = new THREE.Color( 'blue' );
            axisHelper.setColors ( xAxisColor, yAxisColor, zAxisColor);
            scene.add(axisHelper)
            */
            const Axis = new THREE.Group();
            const directions = [
                new THREE.Vector3(1, 0, 0),
                new THREE.Vector3(0, 1, 0),
                new THREE.Vector3(0, 0, 1)
            ];
            const colors = [0xff0000, 0x00ff00, 0x0000ff]; //Farbcodes des Koordinatensystems
        
            directions.forEach((dir, index) => {
                const arrow = new THREE.ArrowHelper(dir, new THREE.Vector3(0,0,0.005), 1.5, colors[index], 0.05, 0.05);
                Axis.add(arrow);
            });
            scene.add(Axis)

            this.scene = scene;

            //-> Passt die Größe des Animationsfensters an, sollte sich die Größe des Fensters ändern
            window.addEventListener('resize', function () {
                camera.aspect = canvas.offsetWidth / canvas.offsetHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(canvas.offsetWidth, canvas.offsetHeight);
            }, false);

            //-> Aufruf der Animationsschleife
            this.animate(table, Q, renderer, scene, camera);
    };

    ThreeDRobot.prototype = Object.create(THREE.Object3D.prototype);
    ThreeDRobot.prototype.constructor = ThreeDRobot;

    //-> Animationsschleife und GUI
    ThreeDRobot.prototype.animate = function(table, Q, renderer, scene, camera) {

        let ChangeOnRefPath = true; 
        let x = 1;
       // stats.begin(); //Beginn Anzeige der FPS

        // Umrechung der Winkel, zu radiant, sollte e sich um ein Schubgelenk handeln
        for (let i = 0; i < table.length; i++) {
            if (this.table[i].h === 0) {
                this.table[i].theta = this.table[i].theta * Math.PI / 180
                this.table[i].alpha = this.table[i].alpha * Math.PI / 180
            }
        }
        const loop = () => {
            //-> wird die Basis / der Nullpunkt des Roboters verschoben, so muss die Referenzbahn / Sollbahn auch verschoben werden
            if(ChangeOnRefPath == true){
                this.createTransformationMatrix({ x: settings.x, y: settings.y, z: settings.z}, { x: settings.xrot, y: settings.yrot, z: settings.zrot })
                this.generateRefPath()
                ChangeOnRefPath = false;
            }
            //-> unterbicht die Animationsschleife sollte dies durch die GUI gesetzt werden
            if(settings.animate) {
                let now = Date.now();
            
                if (x < Q.length) {
                if (now - lastUpdate > settings.speed) {   //Verzögerung durch GUI
                    for (let i = 0; i < table.length; i++) {
                        if (this.table[i].h === 1) {
                            this.table[i].theta = Q[x][i]; // Setzt dynamische DH Parameter für Rotataionsgelenke
                        } else {
                            this.table[i].d = Q[x][i];    // Setzt dybnamische DH Paramter für Schubgelenk
                        }
                    }
                    this.update(x);
                    x++;
                    lastUpdate = now;
                }
                } else {
                    //-> Zurücksetzen der Animation und von neuem beginnen / Endlosschleife der Animation
                    x = 1;
                }
            }
            renderer.render(scene, camera);
          //  stats.end(); //Beendet die Anzeige der FPS

            animationId = requestAnimationFrame(loop);  
        }
            //-> Beginn GUI

            // Überprüfen, ob bereits ein GUI-Element vorhanden ist
            if (this.gui) {
                // Wenn bereits eine GUI vohanden ist, wird sie entfernt
                this.gui.domElement.remove();
            }

            // Erstelle ein neues GUI-Element
            this.gui = new GUI();
            // Füge es dem HTML-Dokument hinzu
            $('#gui').append(this.gui.domElement);

            // -> Erstellt Ordner für die Verschiebung des Ursprungs der Roboters
            const nullpunkt = this.gui.addFolder('Origin');
            nullpunkt.add(settings, 'x', -1.5, 1.5, 0.1).onChange(function () {ChangeOnRefPath =  true; }); 
            nullpunkt.add(settings, 'y', -1.5, 1.5, 0.1).onChange(function () {ChangeOnRefPath =  true; }); 
            nullpunkt.add(settings, 'z', -1.5, 1.5, 0.1).onChange(function () {ChangeOnRefPath =  true; }); 
            nullpunkt.add(settings, 'xrot', 0, 360, 10).onChange(function () {ChangeOnRefPath =  true; });
            nullpunkt.add(settings, 'yrot', 0, 360, 10).onChange(function () {ChangeOnRefPath =  true; });
            nullpunkt.add(settings, 'zrot', 0, 360, 10).onChange(function () {ChangeOnRefPath =  true; });

            // Erstellt Ordner für die Kontrollvariablen
            const controlls = this.gui.addFolder('Controlls');
            controlls.add(settings, 'speed', 0, 100, 5);   
            controlls.add(settings, 'animate');
            controlls.add(settings, 'waypoints');
            controlls.add(settings, 'refpath').onChange(function () {ChangeOnRefPath =  true; });
            controlls.addColor(settings, 'robotcolor').onChange(function () {robotcolorchange=  true; });

            // Erstellt Anzeige für die aktuelle Position des Endeffektors
            const endeffektor = this.gui.addFolder('Endeffektorpose');
            endeffektor.open();
            const ef = endeffektor.add(end, 'pos').listen();
            const eu = endeffektor.add(end, 'euler').listen();
                
            //--> Ende GUI
        // Start des loops
        loop(); 

        
    };
    
        //-->Erstellen und Updaten des Robotters
        ThreeDRobot.prototype.update = function (x) {
           var last //-> handelt es sich u das letzte Gelenk
           var tempTable = Object.create(this.table);   
         
           let tooltype= "gripper"
           this.points = this.points || {};
           this.axis = this.axis || {};
           this.tool = this.tool || {};
           this.arms = this.arms || {};
            
            //-> Berechnung der Transformationsmatrizen (FK)
            var FK = this.computeFK(tempTable)

            //-> erstellt Endpunkt oder auch fiktives Gelenk durch hinzufügen leerer Transformationsmatrizen
            const emptyMatrix = new THREE.Matrix4();
            FK.push(emptyMatrix);
            

            for (var i = 0; i < FK.length-1; i++) {
                //-->Ableiten der Start- und Endpunkte aus den transformationsmatrizen
                const a1 = new THREE.Vector3();
                a1.setFromMatrixPosition(FK[i]);
                const a2 = new THREE.Vector3();
                a2.setFromMatrixPosition(FK[i+1]);

                //-> setzt Flag für letztes Element und erstelle Anzeigetext für GUI, erstellt bahnkurve
                if (i == FK.length-2){
                    last = true;
                    // Anzeigetext der Endeffektorposition
                    end.pos = a1.x.toFixed(2) + ", " + a1.y.toFixed(2) + ", " + a1.z.toFixed(2); 
                   
                    //-> erstellt aus Transformationsmatrix die korrespondierende Euler Rotatationsmatrix sowie den Anzeigetext für die GUI
                    var euler = new THREE.Euler();
                    euler.setFromRotationMatrix(FK[i], 'XYZ');  
                    end.euler = (euler.x * 180 / Math.PI).toFixed(2) + ' '+ (euler.y * 180 / Math.PI).toFixed(2) + ' ' + (euler.z * 180 / Math.PI).toFixed(2);
                    
                    //--> erstellen und updaten der Wegpunkte oder auch IST Punkte
                    if(settings.waypoints) {
                    const waypoint = new Float32Array([a1.x, a1.y, a1.z]);
                    //-> initiale Erstellung der Punkte
                    if (!this.points[x]) {
                        this.points[x] = new CustomTrack(waypoint, 0x000000);
                        this.add(this.points[x]);
                    //--> Update der Punkte, sollten diese bereits existieren     
                    } else{
                       this.points[x].geometry.attributes.position.setXYZ(0, waypoint[0], waypoint[1], waypoint[2])
                       this.points[x].geometry.attributes.position.needsUpdate = true;
                    }
                    //-> Löschn aller Punkte, falls die Anzeige sebilger durch die GUI / den Nutzer abgeschaltet wird
                    }else if (settings.waypoints == false){
                        for (let key in this.points) {
                            if (this.points.hasOwnProperty(key)) {
                                this.remove(this.points[key]);
                                if (this.points[key].geometry) this.points[key].geometry.dispose();
                                if (this.points[key].material) this.points[key].material.dispose();
                                delete this.points[key];
                            }
                        }
                    }
                }else{
                    last = false;
                }
                
                //-> Initiale Erstellung der Gelenke, nur wen isNew wahr ist
                if (!this.axis[i]) {
                    this.axis[i]  = new CustomAxis(last,tempTable[i]); 
                    this.add(this.axis[i]);
                }
                // -> Setzen der Position und Orientierung der Gelenke
                if (this.axis[i] != undefined) {
                    this.axis[i].position.setFromMatrixPosition(FK[i]);
                    this.axis[i].rotation.setFromRotationMatrix(FK[i]);
                }

                 //-> Initiale Erstellung des Werkzeuges
                if (!this.tool[i]&& i== FK.length-2) {
                    this.tool[i]  = new CustomTool(tooltype); 
                    this.add(this.tool[i]);
                }
                // -> Setzen der Position und Orientierung des Werkzeuges
                if (this.tool[i] != undefined) {
                    this.tool[i].position.setFromMatrixPosition(FK[i]);
                    this.tool[i].rotation.setFromRotationMatrix(FK[i]);

                }
                
                //-> Arme zwischen den Gelenken
                if(i<FK.length-2){
                    //-> Start und Endpunkt der Arme
                    const startPoint = new THREE.Vector3(a1.x,a1.y,a1.z);
                    const endPoint = new THREE.Vector3(a2.x,a2.y,a2.z);

                    // Berechnung Länge des Armteils
                    const length = startPoint.distanceTo(endPoint);

                    // Erstellt den Armtile falls diese noch nicht existieren und isNew wahr ist
                  
                    if (!this.arms[i]) {
                        this.arms[i] = new CustomArm(length, i, tempTable[i]);
                        this.arms[i].meta = {lastlength: length}
                        this.add(this.arms[i]);
                    };
                    //Setzen der Poisition und Orientierung der zuvor erstellten Arme
                    if (this.arms[i] != undefined) {

                        // Positioniert den Arm zwischen Start- und Endpunkt
                        this.arms[i].position.addVectors(startPoint, endPoint).multiplyScalar(0.5);
                
                        //aktuallisierung der Länge fals notwendig - nur bei Schubgelenken
                        if(this.arms[i].meta.lastlength != length.toFixed(2)){
                            if (this.arms[i].ExtrusionDirection.direction == "X"){
                                this.arms[i].scale.x *= length.toFixed(2)/ this.arms[i].meta.lastlength;
                                this.arms[i].meta = {lastlength: length.toFixed(2)}
                            }
                            else if (this.arms[i].ExtrusionDirection.direction == "Y"){
                                this.arms[i].scale.y *= length.toFixed(2)/ this.arms[i].meta.lastlength;
                                this.arms[i].meta = {lastlength: length.toFixed(2)}
                            }
                            else if (this.arms[i].ExtrusionDirection.direction == "Z" || this.arms[i].ExtrusionDirection.direction == "schraeggelenk"){
                                this.arms[i].scale.z *= length.toFixed(2)/ this.arms[i].meta.lastlength;
                                this.arms[i].meta = {lastlength: length.toFixed(2)}
                            }
                        };
                        if(robotcolorchange == true){
                            this.arms[i].material.color.setHex(settings.robotcolor)   
                        }
                        //setzen der räumlichen Orientierung der Armteile
                        // -> sollte es sich nicht um ein Schraeggelenk handeln anhand von quanternionen
                        if (this.arms[i].ExtrusionDirection.direction != "schraeggelenk"){
                        const quaternion = new THREE.Quaternion();
                        FK[i+1].decompose(new THREE.Vector3(), quaternion, new THREE.Vector3());
                        this.arms[i].setRotationFromQuaternion(quaternion); 
                        //-> handelt es sich um ein Schraeggelnk wird der Arm in Richtung der beiden Punkte orieniert
                        } else if (this.arms[i].ExtrusionDirection.direction == "schraeggelenk"){
                        this.arms[i].position.addVectors(startPoint, endPoint).multiplyScalar(0.5);
                        this.arms[i].lookAt(startPoint);
                        }
                    };  
                     
                };
            
            };
            robotcolorchange=false
          // console.log(this) // Consolenoutput der Animation
        };

//------------------- Unterstützende Funktionen ----------------------------------------------


        //-> Berechnung der Matrix0 welche die durch die UI eingegebene Verschiebeung uns Anpassung der Orientierung der Robotermatrix in eine Transformationsmatrix umrechnet
        ThreeDRobot.prototype.createTransformationMatrix = function(translation, rotation) {

            //-> Erstellen der Translationsmatrix
            let translationMatrix = new THREE.Matrix4().makeTranslation(
                translation.x, translation.y, translation.z
            );

            //-> Erstellen der Rotationsmatrizen für jede Achse
            let rotationX = new THREE.Matrix4().makeRotationX(rotation.x * Math.PI / 180);
            let rotationY = new THREE.Matrix4().makeRotationY(rotation.y * Math.PI / 180);
            let rotationZ = new THREE.Matrix4().makeRotationZ(rotation.z * Math.PI / 180);

            //-> Kombinieren der Rotationsmatrizen
            let combinedRotation = new THREE.Matrix4()
                .multiply(rotationX)
                .multiply(rotationY)
                .multiply(rotationZ);

            //-> Kombinieren der Translations- und Rotationsmatrizen
             matrix0 =  translationMatrix.multiply(combinedRotation); // Rotation zur Basis des Roboters
            //matrix0.multiplyMatrices(combinedRotation, translationMatrix); //Rotation zur Basis des Weltsystems
        }


        //-> Löschen und generieren der Sollbahn
        ThreeDRobot.prototype.generateRefPath = function () {
            if(settings.refpath) {
                if (this.refpath != undefined) {
                    this.remove(this.refpath)
                }
                this.refpath = new THREE.Group();
                var points = ThreeDRobot.prototype.CalcTracepoints(table, Q);
                for (const point of points) {
                    var tracepoint = new CustomTrack(point, 0x00ff00);
                    tracepoint.geometry.attributes.position.needsUpdate = true;
                    this.refpath.add(tracepoint)
                }
                this.add(this.refpath)
                //-> löschen der Sollbahn, falls über GUI gesetzt
            }else if (settings.refpath == false){
                this.remove(this.refpath)
            }
        };

        //-> Berechnung der Spurpunkte aus gegebeen DH Parametern und der Winkeländerungen, berücksichtigt wird auch die Verschiebung des Nullpunktes
        ThreeDRobot.prototype.CalcTracepoints = function (table, Q) {
            var tempTable = Object.create(table);
            let results = []
 
            for (let x = 0; x < Q.length; x++) {
                for (let i = 0; i < tempTable.length-1; i++) {
                    if (tempTable[i].h === 1) {
                        tempTable[i].theta = Q[x][i]; // Rotataionsgelenk
                    } else {
                        tempTable[i].d = Q[x][i];    // Schubgelenk
                    }
                };      

                var FK = this.computeFK(tempTable)
                var i = FK.length-1
                const a2 = new THREE.Vector3();
                a2.setFromMatrixPosition(FK[i]);

                //Zufallszahl zur erzeugung von Aweichungen, muss angepasst werden, sobald entsprechende Daten vorliegen
                var disc = (Math.random() * (0.008 - (-0.01))) + (-0.01);

                const waypoint = new Float32Array([a2.x+disc, a2.y+disc, a2.z+disc]);
                results.push(waypoint)
        };
        return results
    };

        //-> Berechnung der Transformationsmatrizen aus DH Tabelle
        ThreeDRobot.prototype.computeFK = function (dh) {
            const results = [];
            results.push(matrix0.clone())
            let result = new THREE.Matrix4
            result = matrix0.clone() 

            dh.forEach((params) => {
                const a = params.a;
                const d = params.d;
                const ct = Math.cos(params.theta);
                const st = Math.sin(params.theta);
                const ca = Math.cos(params.alpha);
                const sa = Math.sin(params.alpha);
        
                const matrix = new THREE.Matrix4();
                matrix.set(
                    ct, -st * ca, st * sa, a * ct,
                    st, ct * ca, -ct * sa, a * st,
                    0, sa, ca, d,
                    0, 0, 0, 1
                );
                result = result.multiply(matrix);
                results.push(result.clone());
            });

            return results;
        };

        //-> Funktion erstellt die Arme des Roboters 
        function CustomArm(length, jointNumber, tempTable) {
            //-> orange
            const colors = [0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19]; 
            //-> Grüntöne 
            //const colors = [0x2f4302, 0x415b03, 0x527403, 0x648d04, 0x76a605, 0x87bf05];
            
            var direction =""
            //-> Differzierung in welcher Richtung die initiale Extrusion der Armteile erfolgt, damit die Anwendung der quanternionen für die Positionierung korrekt erfolgen kann
            var lengthX = 0.1
            var lengthY = 0.1
            var lengthZ = 0.1

            if(tempTable.a == 0 && tempTable.d != 0 && tempTable.alpha !=0){
                direction = "Y"
                lengthY=length
            }else if (tempTable.d == 0 && tempTable.a != 0 ){
                direction = "X"
                lengthX=length
            }else if(Math.sin(tempTable.theta).toFixed(2)==0 && Math.sin(tempTable.alpha).toFixed(2) == 0 && tempTable.a == 0){
                direction = "Z"
                lengthZ=length
            //}else if (tempTable.d != 0 && tempTable.a != 0 && tempTable.theta !=0){
            }else if (tempTable.d != 0 && tempTable.a != 0){
                direction="schraeggelenk"
                lengthZ=tempTable.d 
            }else{
                
               // console.log("Fehler bei der Zuordnung der initialen Extrusion") //-> Fehler wird auch angezeigt, wenn a und d 0 sind
               // console.log(tempTable)
            }
            // Eigentliche Erzeugung der Geometrien


            const geometry2 = new THREE.BoxGeometry(lengthX, lengthY, lengthZ);
          //  const material = new THREE.MeshLambertMaterial({ color: colors[jointNumber], });
            const material = new THREE.MeshLambertMaterial({ color: settings.robotcolor, });
            const arm  = new THREE.Mesh(geometry2, material);
            arm.ExtrusionDirection = { direction: direction }; 

            return arm;
        }

        CustomArm.prototype = Object.create(THREE.Object3D.prototype);
        CustomArm.prototype.constructor = CustomArm;

        //-> Funktion erzeugt die Gelenke sowie das Koordinatensystem des Endefektors
        function CustomAxis(last, tempTable) {
            const Axisgr = new THREE.Group();
            // Funktion zum Hinzufügen des Koordinatensystems am Greifer (nur für das letzte Gelenk)
            if(last==true){
                const directions = [
                    new THREE.Vector3(1, 0, 0),
                    new THREE.Vector3(0, 1, 0),
                    new THREE.Vector3(0, 0, 1)
                ];
                const colors = [0xff0000, 0x00ff00, 0x0000ff]; //Farbcodes des Koordinatensystems des Endeffektors
            
                directions.forEach((dir, index) => {
                    const arrow = new THREE.ArrowHelper(dir, new THREE.Vector3(), 0.5, colors[index], 0.05, 0.05);
                    Axisgr.add(arrow);
                });
            };
            // Erzeugen der Gelenke
            if(last==false){
                const jointGeo = new THREE.CylinderGeometry(0.075, 0.075, 0.075 * 2, 32, 32);
                var jointMesh = jointMesh || {};
  
                if(tempTable.h!==0){
                    jointMesh = new THREE.Mesh(jointGeo, new THREE.MeshLambertMaterial({color: 0x3e3e3e})); 
                }else{
                   jointMesh = new THREE.Mesh(jointGeo, new THREE.MeshLambertMaterial({color: 0x686e71})); 
                }
                jointMesh.rotation.x = Math.PI / 2;
                Axisgr.add(jointMesh);
            }
        return Axisgr
        }
        CustomAxis.prototype = Object.create(THREE.Object3D.prototype);
        CustomAxis.prototype.constructor = CustomAxis;

        //-> Funktion zum Erstellen des Werkzeuges
        function CustomTool(tooltype) {
            var boxGeometries = boxGeometries || {};
            
            //-> fuer die Orientierung des Werkzeuges besteht keine Konvention, es wird zwischen den implememtierten Robotern unterschieden oder, sollte es sich nicht um einen hinterlegten Roboter handeln, eine Standartkonfiguration gewaehlt 
            if(tooltype == "gripper"){
                if (["SCARA", "R6"].includes(robot.name)) {   
                    boxGeometries = [
                        new THREE.BoxGeometry(0.12, 0.2, 0.05).translate(0, -0.001, 0),
                        new THREE.BoxGeometry(0.12, 0.05, 0.15).translate(0, 0.075, 0.08),
                        new THREE.BoxGeometry(0.12, 0.05, 0.15).translate(0, -0.075, 0.08)
                    ];
                }else if (robot.name === "R3_Planar") {
                    boxGeometries = [                                
                        new THREE.BoxGeometry(0.05, 0.2, 0.12).translate(-0.01, 0, 0),
                        new THREE.BoxGeometry(0.15, 0.05, 0.12).translate(0.05, 0.075, 0),
                        new THREE.BoxGeometry(0.15, 0.05, 0.12).translate(0.05, -0.075, 0)
                    ];
                }
                else{
                    boxGeometries = [
                        new THREE.BoxGeometry(0.12, 0.2, 0.05).translate(0, -0.001, 0),
                        new THREE.BoxGeometry(0.12, 0.05, 0.15).translate(0, 0.075, 0.08),
                        new THREE.BoxGeometry(0.12, 0.05, 0.15).translate(0, -0.075, 0.08)
                    ];
                }
                
                const singleGeometry = BufferGeometryUtils.mergeGeometries(boxGeometries);
                const gripper = new THREE.Mesh(singleGeometry, new THREE.MeshLambertMaterial({color: 0x4d4d4d}));
                return gripper;

            }else if(tooltype == "bohrer"){
                // Schaft des Bohrers
                const Geometries = [
                new THREE.CylinderGeometry(0.02, 0.02, 0.11, 35).translate(0, 0.05, 0),    
                new THREE.CylinderGeometry(0, 0.025, 0.08, 35).translate(0, 0.13, 0),
                ];

                const bohrerMaterial = new THREE.MeshBasicMaterial({ color: 0x808080 });
                const bohrerGeometry = BufferGeometryUtils.mergeGeometries(Geometries);     
                const bohrer = new THREE.Mesh(bohrerGeometry, bohrerMaterial);
                bohrer.rotation.x = Math.PI / 2;
                return bohrer;
            }
        }        
        CustomTool.prototype = Object.create(THREE.Object3D.prototype);
        CustomTool.prototype.constructor = CustomTool;

        //-> Funktion erstellt die Spurpunkte des TCP und der Sollbahn
        function CustomTrack(waypoint, pointcolor) {
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(waypoint, 3));
            const material = new THREE.PointsMaterial({ color: pointcolor, size: 0.02 });
            const pointMesh = new THREE.Points(geometry, material);
            return pointMesh;
        }
        CustomTrack.prototype = Object.create(THREE.Object3D.prototype);
        CustomTrack.prototype.constructor = CustomTrack;

        //-> eigentliher Aufruf der Anmitaion
        new ThreeDRobot(canvas, table, Q);
    };


//-> Ablauf beim Schließen des Modals
componentWillUnmount() {
    console.log('Unmounting RobotAnimationModal');


    // Unterbricht die Animationsschleife
    if (animationId !== null) {
      cancelAnimationFrame(animationId);
      animationId = null;
    }
    // Löscht sämtliche Elemente der Szene
    for( var i = scene.children.length - 1; i >= 0; i--) { 
       var obj = scene.children[i];
        scene.remove(obj); 
    }
    this.renderer.dispose();
    //-> auflösen des Eventlisteners auf Änerungen der Fenstergröße
    window.removeEventListener('resize', this.handleResize);
  }
   
//-> Modal oder "Fenster"
 render() {
        return (
            <div>
             <Modal onHide={this.handleModalClose} closeButton></Modal>
                <Modal.Header onHide={this.handleModalClose} closeButton>
                    <Modal.Title>
                        3D Animation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="canvas">
					<div id="gui"></div>
                    <div style={{ display: 'flex' }}>
        <ul>
          <li style={{ color: 'red' }}>X-Axis</li>
        </ul>
        <ul>
        <li style={{ color: 'green' }}>Y-Axis</li>
        </ul>
        <ul>
          <li style={{ color: 'blue' }}>Z-Axis</li>
        </ul>

      </div>
				</div>
            </Modal.Body>
            </div>
        )
    }
}

export default easyComp(RobotAnimationModal);